import * as production from './environment.prod';

export const environment = {
    production: false,
    development: true,
    versionControl: false,
    syncfusionApi: 'https://app-shippingauth-pre-002.azurewebsites.net',
    backendApi: 'https://app-shippingapi-pre-002.azurewebsites.net',
    trampWeb: 'https://pre-tramp.romeushipping.com/',
    documentationApi: 'https://shipping-shippy-dev-documentationapi-app.azurewebsites.net',
    InstrumentationKey: '2b738270-dd1c-4055-ac6d-19ea4d438171',
    driverVHost: 'https://pickup',
    shippingWeb: 'https://pre-shippy.romeushipping.com',
    whatsNewWeb: production.environment.whatsNewWeb,
    enabledLogging: true,
    tagAnalytics: undefined,
    businessAudit: false,
    environmentApi: 'https://app-shippingapi-pre-002.azurewebsites.net/api/enviroment',
};
